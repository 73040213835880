/* Forked from SOLID Bootstrap 3 Theme by Carlos Alvarez http://alvarez.is */

@import url(http://fonts.googleapis.com/css?family=Raleway:400,700,900);
@import url(http://fonts.googleapis.com/css?family=Lato:400,900);
@import url(http://fonts.googleapis.com/css?family=Merriweather:400italic,300italic);

/*  ################################################################
    1. GENERAL STRUCTURES
################################################################# */
* {
  margin: 0;
  padding: 0px;
}

body {
  background: #390909;
  margin: 0;
  height: 100%;
  color: #4d3434;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  color: #970202;
}

p {
  padding: 0;
  margin-bottom: 12px;
  margin-top: 10px;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4d3434;
}

ul, li {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4d3434;
}

div {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

img {
  height: auto;
  max-width: 100%;
}

ul, ol {
  margin-left: 1.75em;
}

li {
  /*padding-left: 20px;*/
  /*padding: 0px 0px 8px 0px;*/
}

a {
  color: #067500;
  padding: 0;
  margin: 0;
  text-decoration: none;
  /*    -webkit-transition: background-color .4s linear, color .4s linear;
    -moz-transition: background-color .4s linear, color .4s linear;
    -o-transition: background-color .4s linear, color .4s linear;
    -ms-transition: background-color .4s linear, color .4s linear;
    transition: background-color .4s linear, color .4s linear;
  */
}

a:hover,
a:focus {
  text-decoration: none;
  /*color:#01b2fe;*/
  color: #00b4d4;
}

::-moz-selection {
  color: #fff;
  text-shadow: none;
  background: #2B2E31;
}

::selection {
  color: #fff;
  text-shadow: none;
  background: #2B2E31;
}

.centered {
  text-align: center;
}

/*  ################################################################
    BOOTSTRAP MODIFICATIONS & TWEAKS
################################################################# */
.navbar {
  min-height: 50px;
  padding-top: 0px;
  margin-bottom: 0px;
}

.navbar-brand {
  font-family: 'Raleway', sans-serif;
  font-weight: 900;
  padding: 3px;
}

.navbar-brand img {
  height: auto;
  width: 172px;
}

.navbar-header .navbar-brand {
  color: #ffffff;
}

.navbar-default .navbar-nav > li > a {
  color: #ffffff;
  font-weight: 700;
  font-size: 14px;
}

.navbar-default .navbar-nav > li > a:focus, .navbar-default .navbar-nav > li > a:active:focus, .navbar-default .navbar-nav > li > a.active:focus {
  color: #f8b564;
}

.navbar-default .navbar-nav > li > a:hover {
  color: #f8b564;
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
  color: #f8b564;
  background-color: transparent;
}

.navbar-default {
  background-color: #390909;
  border-color: transparent;
}

.nav-item{
  text-align: right;
}

.dropdown-menu {
  background: #390909;
}

.dropdown-menu > li > a {
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
}

.btn, .btn:focus, .btn:active:focus, .btn.active:focus {
  border: 0 none;
  border-bottom: none;
  background-color: none;
  border-radius: 14px;
  font-size: 16px;
  padding: 7px 16px;
  margin: 4px 4px;
  outline: 0 none;
}

.btn-lg, .btn-lg:focus, .btn-lg:active:focus, .btn-lg.active:focus {
  font-size: 18px;
  padding: 10px 20px;
  outline: 0 none;
}

.btn-primary {
  color: #fff;
  background-color: #c1651b;
  border-bottom: 3px solid #9a4d0e;
}

.btn-primary:hover {
  color: #fff;
  background-color: #e47523;
  border-bottom: 3px solid #9a4d0e;
}

.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary:active:focus, .btn-primary.active:focus {
  color: #fff;
  background-color: #e47523;
  border-bottom: 3px solid #9a4d0e;
}

.btn-buy {
  color: #fff;
  background-color: #31b119;
  border-bottom: 3px solid #21880c;
}

.btn-buy:hover {
  color: #fff;
  background-color: #38c91c;
  border-bottom: 3px solid #21880c;
}

.btn-buy:focus,
.btn-buy:active,
.btn-buy.active,
.btn-buy:active:focus, .btn-buy.active:focus {
  color: #fff;
  background-color: #38c91c;
  border-bottom: 3px solid #21880c;
}

/*.btn {
    padding: 14px 24px;
    border: 0 none;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    background: #cc99cc;
}
 
.btn:focus, .btn:active:focus, .btn.active:focus {
    outline: 0 none;
    background: #cc99cc;
}

.btn-primary {
    background: #cc99cc;
    color: #ffffff;
}
 
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open > .dropdown-toggle.btn-primary {
    background: #cca6cc;
}
 
.btn-primary:active, .btn-primary.active {
    background: #cc7299;
    box-shadow: none;
}
*/
.dmbutton:hover,
.dmbutton:active,
.dmbutton:focus {
  color: #ffffff;
  background-color: #222222;
  border-color: #ffffff;
}

.dmbutton {
  background: transparent;
  border: 1px solid #ffffff;
  color: #ffffff;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  padding-top: 1.025rem;
  padding-right: 2.25rem;
  letter-spacing: 0.85px;
  padding-bottom: 1.0875rem;
  padding-left: 2.25rem;
  font-size: 1.55rem;
  cursor: pointer;
  font-weight: normal;
  line-height: normal;
  margin: 0 0 1.25rem;
  text-decoration: none;
  text-align: center;
  display: inline-block;
  -webkit-transition: background-color 300ms ease-out;
  -moz-transition: background-color 300ms ease-out;
  transition: background-color 300ms ease-out;
  -webkit-appearance: none;
  font-weight: normal !important;
}

.mtb {
  margin-top: 50px;
  margin-bottom: 50px;
}

.mb {
  margin-bottom: 50px;
}

.mt {
  margin-top: 50px;
}

.smtb {
  margin-top: 30px;
  margin-bottom: 30px;
}

.smb {
  margin-bottom: 30px;
}

.smt {
  margin-top: 30px;
}

.ptb {
  padding-top: 50px;
  padding-bottom: 50px;
}

.pb {
  padding-bottom: 50px;
}

.pt {
  padding-top: 50px;
}

.hline {
  border-bottom: 2px solid #4d3434;
}

.hline-w {
  border-bottom: 2px solid #ffffff;
  margin-bottom: 25px;
}

/*  ################################################################
    SITE WRAPS
################################################################# */
#headerwrap {
  // background-color: #390909;
  background-color: #4d3434;
  // min-height: 250px;
  padding-top: 10px;
  padding-bottom: 0px;
  text-align: center;
  // background: #3d1d25 url(../img/heartwild_solitaire_dreams/main_header_bkg.jpg) no-repeat center top;
  background: #390909;// url(../gfx/boot/banner_solitaire_dreams_bkg1.jpg) no-repeat center top;
  background-attachment: relative;
  background-position: center center;
  width: 100%;
  -webkit-background-size: 100%;
  -moz-background-size: 100%;
  -o-background-size: 100%;
  background-size: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#headerwrap h3, h5 {
  color: #fff;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 10px;
  // text-shadow: 1px 1px #ccc;
}

#headerwrap h1 {
  color: #ffbf6b;
  margin-top: 20px;
  margin-bottom: 0px;
  font-weight: bold;
  // text-shadow: 1px 1px #ccc;
  font-family: 'Merriweather', serif; font-weight: 500; font-style: italic;
}

#headerwrap .img-responsive {
  /*margin: 0 auto;*/
}

/*@media (min-width: 640px) { 
#headerwrap { min-height: 400px; }
}

@media (min-width: 768px) { 
#headerwrap { min-height: 450px; }
}

@media (min-width: 992px) { 
#headerwrap { min-height: 500px; }
}

@media (min-width: 1200px) { 
#headerwrap { min-height: 550px; }
}
*/
#graven #gameheaderwrap {
  background-color: #1b222a;
  min-height: 280px;
  padding-top: 10px;
  padding-bottom: 0px;
  text-align: center;
  background: #1b222a url(../gfx/boot/header_graven.jpg) no-repeat center top;
  background-attachment: relative;
  background-position: center center;
  width: 100%;
  -webkit-background-size: auto 100%;
  -moz-background-size: auto 100%;
  -o-background-size: auto 100%;
  background-size: auto 100%;
  /*    -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
  */
}

#royal2 #gameheaderwrap {
  background-color: #4d0714;
  min-height: 280px;
  padding-top: 10px;
  padding-bottom: 0px;
  text-align: center;
  background: #2f0507 url(../gfx/boot/header_rt2.jpg) no-repeat center top;
  background-attachment: relative;
  background-position: center center;
  width: 100%;
  -webkit-background-size: auto 100%;
  -moz-background-size: auto 100%;
  -o-background-size: auto 100%;
  background-size: auto 100%;
}

#modern_tales #gameheaderwrap {
  background-color: #17202e;
  min-height: 280px;
  padding-top: 10px;
  padding-bottom: 0px;
  text-align: center;
  background: #17202e url(../gfx/boot/header_modern_tales.jpg) no-repeat center top;
  background-attachment: relative;
  background-position: center center;
  width: 100%;
  -webkit-background-size: auto 100%;
  -moz-background-size: auto 100%;
  -o-background-size: auto 100%;
  background-size: auto 100%;
}

#heartwild1 #gameheaderwrap {
  background-color: #390909;
  min-height: 150px;
  padding-top: 10px;
  padding-bottom: 0px;
  text-align: center;
  background: #390909 url(../gfx/boot/header_heartwild1.jpg?1) no-repeat center top;
  background-attachment: relative;
  background-position: center center;
  width: 100%;
  -webkit-background-size: 100%;
  -moz-background-size: 100%;
  -o-background-size: 100%;
  background-size: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#heartwild2 #gameheaderwrap {
  background-color: #390909;
  min-height: 150px;
  padding-top: 10px;
  padding-bottom: 0px;
  text-align: center;
  background: #390909 url(../gfx/boot/header_heartwild2.jpg) no-repeat center top;
  background-attachment: relative;
  background-position: center center;
  width: 100%;
  -webkit-background-size: 100%;
  -moz-background-size: 100%;
  -o-background-size: 100%;
  background-size: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#royal1 #gameheaderwrap {
  background-color: #efe7d0;
  min-height: 260px;
  padding-top: 10px;
  padding-bottom: 0px;
  text-align: center;
  background: #efe7d0 url(../gfx/boot/banner_royal1.jpg) no-repeat center top;
  background-attachment: relative;
  background-position: center center;
  width: 100%;
  -webkit-background-size: 100%;
  -moz-background-size: 100%;
  -o-background-size: 100%;
  background-size: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#spiritwalkers #gameheaderwrap {
  background-color: #efe7d0;
  min-height: 260px;
  padding-top: 10px;
  padding-bottom: 0px;
  text-align: center;
  background: #efe7d0 url(../gfx/boot/banner_spiritwalkers.jpg) no-repeat center top;
  background-attachment: relative;
  background-position: center center;
  width: 100%;
  -webkit-background-size: 100%;
  -moz-background-size: 100%;
  -o-background-size: 100%;
  background-size: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

$solitaire_dreams_header_bkg: #1c041a;

#heartwild_dreams #gameheaderwrap {
  background-color: $solitaire_dreams_header_bkg;
  min-height: 270px;
  padding-top: 10px;
  padding-bottom: 0px;
  text-align: center;
  background: $solitaire_dreams_header_bkg url(../img/heartwild_solitaire_dreams/header_sd.jpg) no-repeat center top;
  background-attachment: relative;
  background-position: center center;
  width: 100%;
  -webkit-background-size: 100%;
  -moz-background-size: 100%;
  -o-background-size: 100%;
  background-size: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

$collector_solitaire_header_bkg: #331625;

#collector_solitaire #gameheaderwrap {
  background-color: $collector_solitaire_header_bkg;
  background: $collector_solitaire_header_bkg url(../img/collector_solitaire/header_cs.jpg) no-repeat center top;
  min-height: 280px;
  padding-top: 10px;
  padding-bottom: 0px;
  text-align: center;
  background-attachment: relative;
  background-position: center center;
  width: 100%;
  -webkit-background-size: auto 100%;
  -moz-background-size: auto 100%;
  -o-background-size: auto 100%;
  background-size: auto 100%;
}

$solitaire_royals_header_bkg: #331625;

#solitaire_royals #gameheaderwrap {
  background-color: $solitaire_royals_header_bkg;
  background: $solitaire_royals_header_bkg url(../img/solitaire_royals/header_royals.jpg) no-repeat center top;
  min-height: 280px;
  padding-top: 10px;
  padding-bottom: 0px;
  text-align: center;
  background-attachment: relative;
  background-position: center center;
  width: 100%;
  -webkit-background-size: auto 100%;
  -moz-background-size: auto 100%;
  -o-background-size: auto 100%;
  background-size: auto 100%;
}

@media (min-width: 512px) {
  #heartwild_dreams #gameheaderwrap {
    min-height: 340px;
  }
}
@media (min-width: 640px) {
  #graven #gameheaderwrap {
    min-height: 430px;
  }

  #royal2 #gameheaderwrap {
    min-height: 430px;
  }

  #modern_tales #gameheaderwrap {
    min-height: 430px;
  }

  #heartwild1 #gameheaderwrap {
    min-height: 250px;
  }

  #heartwild2 #gameheaderwrap {
    min-height: 250px;
  }

  #royal1 #gameheaderwrap {
    min-height: 370px;
  }

  #spiritwalkers #gameheaderwrap {
    min-height: 370px;
  }

  #heartwild_dreams #gameheaderwrap {
    min-height: 500px;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    -o-background-size: auto 100%;
    background-size: auto 100%;
  }

  #collector_solitaire #gameheaderwrap {
    min-height: 430px;
  }

  #solitaire_royals #gameheaderwrap {
    min-height: 430px;
  }
}
@media (min-width: 768px) {
  #graven #gameheaderwrap {
    min-height: 540px;
  }

  #royal2 #gameheaderwrap {
    min-height: 540px;
  }

  #modern_tales #gameheaderwrap {
    min-height: 540px;
  }

  #heartwild1 #gameheaderwrap {
    min-height: 350px;
  }

  #heartwild2 #gameheaderwrap {
    min-height: 350px;
  }

  #royal1 #gameheaderwrap {
    min-height: 460px;
  }

  #spiritwalkers #gameheaderwrap {
    min-height: 460px;
  }

  #collector_solitaire #gameheaderwrap {
    min-height: 540px;
  }

  #solitaire_royals #gameheaderwrap {
    min-height: 540px;
  }

  /*#heartwild_dreams #gameheaderwrap { min-height: 350px; }*/
}
@media (min-width: 992px) {
  #graven #gameheaderwrap {
    min-height: 660px;
  }

  #royal2 #gameheaderwrap {
    min-height: 660px;
  }

  #modern_tales #gameheaderwrap {
    min-height: 660px;
  }

  #heartwild1 #gameheaderwrap {
    min-height: 450px;
  }

  #heartwild2 #gameheaderwrap {
    min-height: 450px;
  }

  #royal1 #gameheaderwrap {
    min-height: 540px;
  }

  #spiritwalkers #gameheaderwrap {
    min-height: 540px;
  }

  /*#heartwild_dreams #gameheaderwrap { min-height: 450px;
   }
  */
  
  #collector_solitaire #gameheaderwrap {
    min-height: 660px;
  }

  #solitaire_royals #gameheaderwrap {
    min-height: 660px;
  }
}
@media (min-width: 1200px) {
  #graven #gameheaderwrap {
    min-height: 740px;
  }

  #royal2 #gameheaderwrap {
    min-height: 740px;
  }

  #modern_tales #gameheaderwrap {
    min-height: 740px;
  }

  #heartwild1 #gameheaderwrap {
    min-height: 550px;
  }

  #heartwild2 #gameheaderwrap {
    min-height: 550px;
  }

  #royal1 #gameheaderwrap {
    min-height: 640px;
  }

  #spiritwalkers #gameheaderwrap {
    min-height: 640px;
  }

  #heartwild_dreams #gameheaderwrap {
    min-height: 600px;
  }

  #collector_solitaire #gameheaderwrap {
    min-height: 740px;
  }

  #solitaire_royals #gameheaderwrap {
    min-height: 740px;
  }

}
hr {
  border-color: #4d0714;
  width: 25%;
  margin-left: auto;
  margin-right: auto;
  border-width: 2px;
}

.divider {
  color: #4d0714;
  font-size: 40px;
  text-align: center;
}

.subheader h1 {
  font-family: 'Merriweather', serif;
  font-weight: 300;
  font-style: italic;
  font-size: 30px;
  margin-top: 0;
  margin-bottom: 0px;
  color: #901010;
}

.subheader h2 {
  font-family: 'Merriweather', serif;
  font-weight: 300;
  font-style: italic;
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 0px;
  color: #901010;
}

.subheader h3 {
  font-family: 'Merriweather', serif;
  font-weight: 300;
  font-style: italic;
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 0px;
  color: #901010;
}

.subheader hr {
  margin-top: 7px;
  margin-bottom: 30px;
  width: 25%;
  border-color: #801010;
}

.subheader hr.smallmargin {
  margin-top: 7px;
  margin-bottom: 15px;
  width: 25%;
  border-color: #801010;
}

.quote {
  font-family: 'Merriweather', serif;
  font-weight: 300;
  font-style: italic;
  font-size: 24px;
}

/* Services Wrap */
#service {
  margin-top: 100px;
  margin-bottom: 80px;
}

#service i {
  color: #00b3fe;
  font-size: 60px;
  padding: 15px;
}

/* Portfolio Wrap */
#portfoliowrap {
  padding-top: 60px;
  margin-bottom: 60px;
  display: block;
  text-align: center;
}

#portfoliowrap h3 {
  margin-bottom: 25px;
}

.portfolio {
  padding: 0 !important;
  margin: 0 !important;
  display: block;
}

.portfolio-item {
  padding: 3px;
}

.portfolio-item .title:before {
  border-radius: 0;
  display: none;
}

.portfolio-item p {
  margin: 0px 0 30px;
}

.portfolio-item h3 {
  margin: -10px 0 10px;
  font-size: 16px;
  text-transform: uppercase;
}

.tpl6 h3 {
  color: #fff;
  margin: 0;
  padding: 40px 5px 0;
  font-size: 16px;
  text-transform: uppercase;
}

.tpl6 .dmbutton {
  display: inline-block;
  margin: 30px 5px 20px 5px;
  font-size: 13px;
}

.tpl6 .bg {
  height: 100%;
  width: 100%;
  background-color: #00b3fe;
  background-color: rgba(0, 179, 254, 0.9);
  text-align: center;
}

/* Testimonials Wrap */
#twrap {
  background: url(../img/t-back.jpg) no-repeat center top;
  margin-top: 0px;
  padding-top: 60px;
  text-align: center;
  background-attachment: relative;
  background-position: center center;
  min-height: 450px;
  width: 100%;
  -webkit-background-size: 100%;
  -moz-background-size: 100%;
  -o-background-size: 100%;
  background-size: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#twrap i {
  font-size: 50px;
  color: white;
  margin-bottom: 25px;
}

#twrap p {
  color: white;
  font-size: 15px;
  line-height: 30px;
}

/* clients logo */
#cwrap {
  background: #f7f7f7;
  margin-top: 0px;
  padding-top: 80px;
  padding-bottom: 100px;
}

#cwrap h3 {
  margin-bottom: 60px;
}

/* Footer */
#footerwrap {
  padding-top: 40px;
  padding-bottom: 20px;
  background: #390909;
}

#footerwrap p {
  color: #bfc9d3;
}

#footerwrap h4 {
  color: white;
}

/*#footerwrap i {
    font-size: 30px;
    color: #bfc9d3;
    padding-right: 25px;
}

#footerwrap i:hover {
    color: #00b3fe
}*/
/*  ################################################################
    PAGE CONFIGURATIONS
################################################################# */
/* General Tweaks */
.bgdark {
  background: #4d0714;
}

.bgdark h3, .bgdark h2, .bgdark h4, .bgdark p {
  color: #efe7d0;
}

#blue {
  background: #00b3fe;
  margin-top: 60px;
  margin-bottom: 60px;
  padding-top: 25px;
  padding-bottom: 25px;
}

#blue h3 {
  color: white;
  margin-left: 15px;
}

.ctitle {
  color: #00b3fe;
  font-weight: 700;
  margin-bottom: 15px;
}

.csmall {
  font-size: 14px;
  color: #b3b3b3;
}

.csmall2 {
  font-size: 14px;
  color: #f39c12;
}

.spacing {
  margin-top: 40px;
  margin-bottom: 40px;
}

.badge-theme {
  background: #00b3fe;
}

/* Contact Page */
#contactwrap {
  background: url(../img/contact.jpg) no-repeat center top;
  margin-top: -60px;
  padding-top: 0px;
  text-align: center;
  background-attachment: relative;
  background-position: center center;
  min-height: 400px;
  width: 100%;
  -webkit-background-size: 100%;
  -moz-background-size: 100%;
  -o-background-size: 100%;
  background-size: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

/* Blog Page */
.popular-posts {
  margin: 0px;
  padding-left: 0px;
}

.popular-posts li {
  list-style: none;
  margin-bottom: 20px;
  min-height: 70px;
}

.popular-posts li a,
.popular-posts li a:hover {
  color: #2f2f2f;
  text-decoration: none;
}

.popular-posts li img {
  float: left;
  margin-right: 20px;
}

.popular-posts li em {
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #b3b3b3;
}

.popular-posts p {
  line-height: normal;
  margin-bottom: auto;
}

.share i {
  padding-right: 15px;
  font-size: 16px;
}

div.hidden_area {
  display: none;
}

input {
  min-height: 40px;
  border-radius: 2px;
  line-height: 20px;
  padding: 6px 12px;
  border: 1px solid #f8b564;
  margin: 4px;
  background-color: #fff;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

form, label, input, .form-control {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  line-height: 24px;
}

.subscribe-input {
  float: left;
  text-align: left;
  margin-right: 8px;
}

.subscribe-submit {
  right: 0;
}

.alert {
  font-size: 16px;
}

.alert-danger {
  background-color: #ffaaaa;
  border-color: #aa7777;
}

/* --- Blog ------------------------------------------------------- */
.blog .header_info {
  /*font-style: italic;*/
  font-size: 80%;
}

.blog_list h3 {
  margin-top: 50px;
}

.blog_list .header_info {
  /*font-style: italic;*/
  font-size: 80%;
}

/*.bcomment_add {
  margin: 10px 0 20px 0;
  color: #000;
  width: 530px;
}

.bcomment_add .section {
  margin-bottom: 10px;
}

.bcomment_add .section .hdr {
  margin-bottom: 3px;
}
*/
#flash_notice {
  background-color: #363;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  color: #fff;
  font-size: 18px;
}

#flash_alert {
  background-color: #833;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  color: #fff;
  font-size: 18px;
}

#mainwrap {
  background-color: #efe7d0;
}

/* ===================== GRAVEN */
#graven {
  background-color: #1b222a;
}

#graven .navbar-default {
  background-color: #1b222a;
  border-color: transparent;
}

#graven #mainwrap {
  background-color: #1b222a;
  color: #e0e0e0;
}

#graven #footerwrap {
  background-color: #1b222a;
}

#graven #mainwrap p {
  color: #e0e0e0;
  font-size: 18px;
}

#graven #mainwrap h1, #graven #mainwrap h2, #graven #mainwrap h3 {
  color: #5ee5da;
}

#graven .subheader h2 {
  font-family: 'Merriweather', serif;
  font-weight: 300;
  font-style: italic;
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 0px;
  color: #5ee5da;
}

#graven .subheader hr {
  border-color: #5ee5da;
}

#graven #mainwrap a.themed, #graven #mainwrap .themed a.themed:visited {
  color: #ffd386;
}

#graven #mainwrap a.themed:hover {
  color: #00b4d4;
}

#graven #screenshots img {
  border: 3px solid #333;
}

#graven img.wt_img {
  display: block;
  margin: 50px 0 20px 0;
  border: 3px solid #333;
}

#graven span.wt_ref {
  /*font-weight: bold;
  font-style: italic;*/
}

#graven span.wt_key {
  font-weight: bold;
  color: #d3ff86;
}

/* ===================== ROYAL2 */
#royal2 {
  background-color: #2f0507;
}

#royal2 .navbar-default {
  background-color: #2f0507;
  border-color: transparent;
}

#royal2 #mainwrap {
  background-color: #2f0507;
  color: #e0e0e0;
}

#royal2 #footerwrap {
  background-color: #2f0507;
}

#royal2 #mainwrap p {
  color: #e0e0e0;
  font-size: 18px;
}

#royal2 #mainwrap h1, #royal2 #mainwrap h2, #royal2 #mainwrap h3 {
  color: #FFB155;
}

#royal2 .subheader h2 {
  font-family: 'Merriweather', serif;
  font-weight: 300;
  font-style: italic;
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 0px;
  color: #FFB155;
}

#royal2 .subheader hr {
  border-color: #FFB155;
}

#royal2 #mainwrap a.themed, #royal2 #mainwrap a.themed:visited {
  color: #aacd67;
}

#royal2 #mainwrap a.themed:hover {
  color: #00b4d4;
}

#royal2 #screenshots img {
  border: 3px solid #baa7c4;
}

#royal2 img.wt_img {
  display: block;
  margin: 50px 0 20px 0;
  border: 3px solid #333;
}

#royal2 span.wt_ref {
  font-weight: bold;
  /*font-style: italic;*/
}

#royal2 span.wt_key {
  font-weight: bold;
  color: #d3ff86;
}

/* ===================== MODERN_TALES */
#modern_tales {
  background-color: #17202e;
}

#modern_tales .navbar-default {
  background-color: #17202e;
  border-color: transparent;
}

#modern_tales #mainwrap {
  background-color: #17202e;
  color: #e0e0e0;
}

#modern_tales #footerwrap {
  background-color: #17202e;
}

#modern_tales #mainwrap p {
  color: #e0e0e0;
  font-size: 18px;
}

#modern_tales #mainwrap h1, #modern_tales #mainwrap h2, #modern_tales #mainwrap h3 {
  color: #ffcf70;
}

#modern_tales .subheader h2 {
  font-family: 'Merriweather', serif;
  font-weight: 300;
  font-style: italic;
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 0px;
  color: #ffcf70;
}

#modern_tales .subheader hr {
  border-color: #ffcf70;
}

#modern_tales #mainwrap a.themed, #modern_tales #mainwrap a.themed:visited {
  color: #ffd386;
}

#modern_tales #mainwrap a.themed:hover {
  color: #00b4d4;
}

#modern_tales #screenshots img {
  border: 3px solid #333;
}

/* ================= */
#heartwild1 #mainwrap p {
  /*font-size: 18px;*/
}

#heartwild1 #screenshots img {
  border: 4px solid #fff;
}

#heartwild2 #screenshots img {
  border: 4px solid #fff;
}

#royal1 #screenshots img {
  border: 4px solid #fff;
}

#spiritwalkers #screenshots img {
  border: 4px solid #fff;
}

#heartwild_dreams .navbar-default {
  background-color: $solitaire_dreams_header_bkg;
  border-color: transparent;
}

#collector_solitaire .navbar-default {
  background-color: $collector_solitaire_header_bkg;
  border-color: transparent;
}

#solitaire_royals .navbar-default {
  background-color: $solitaire_royals_header_bkg;
  border-color: transparent;
}

.forum .thread {
  margin: 12px 0;
  font-size: 18px;
}

.forum .post_header {
  margin-bottom: 6px;
}

@media (min-width: 768px) {
  .forum .post_header {
    text-align: right;
    margin-right: 8px;
  }
}
.forum .post_body {
  /*padding-bottom: 20px;*/
  border-bottom: 2px solid #dbc6a5;
  position: relative;
  background: #edcda6;
  background: #fffcf2;
  padding: 14px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  margin-left: 6px;
  margin-right: 6px;
}

.forum .post_body:after {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 8px 19px 8px 0;
  border-color: transparent #fffcf2;
  display: block;
  width: 0;
  z-index: 1;
  left: -19px;
  top: 14px;
}

.forum .fpost_list .thread {
  margin: 10px 0px;
}

.forum .fpost_list .thread .theader {
  font-weight: bold;
}

.forum .fpost_list .thread .tuser {
  font-size: 85%;
}

.forum .fpost_list .thread .tpostcount {
  color: #666;
}

img.help_screen {
  border: 3px solid #ccc;
  margin: 5px 0 5px 0;
}

.help img {
  border: 3px solid #ccc;
  margin: 5px 0 5px 0;
}

.help a {
  text-decoration: underline;
}

img.non-responsive {
  height: auto;
  max-width: none;
}

.fbigger {
  font-size: 18px;
}

.fbigger p, .fbigger ul, .fbigger li {
  font-size: 18px;
}

#testimonialwrap {
  background: #f0eeea;
  border-top: 2px solid #e0dee0;
  border-bottom: 2px solid #e0dee0;
  color: #303030;
  text-align: center;
  /*min-height: 160px;*/
  padding-top: 30px;
  padding-bottom: 30px;
  height: 100%;
}

.testimonials {
  text-align: center;
}

.testimonials h4 {
  font-weight: 400;
  font-size: 16px;
  color: #333;
}

.testimonials p {
  font-family: 'Merriweather', serif;
  font-weight: 300;
  font-style: italic;
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 0px;
  color: #901010;
}

.testimonials img {
  margin: 10px auto;
}

img.main_portfolio {
  border-radius: 12px;
  border: 3px #fff solid;
  box-shadow: 3px 3px 6px #aaa;
}

.price_tag {
  font-weight: bold;
}

.highlight {
  color: #000;
  background: #56e4fd;
  padding: 0 3px 0 3px;
}

blockquote {
  border-left: 3px solid #e8c86b;
  padding-top: 0;
  padding-bottom: 0;
}

.discontinued {
  padding: 10px 26px;
}

#mainwrap .discontinued p {
  // font-style: italic;
}

#graven #mainwrap .discontinued { background: #2b3643; border-left: 1px solid #415266; }
#graven #mainwrap .discontinued b { background: rgb(146, 20, 108); }
#graven #mainwrap .discontinued p { font-size: 16px; }

#royal2 #mainwrap .discontinued {   background-color: #49080b; border-left: 1px solid #66521b;}
#royal2 #mainwrap .discontinued b { background: rgb(82, 20, 163); }
#royal2 #mainwrap .discontinued p { font-size: 16px; }

#royal1 #mainwrap .discontinued {   background-color: #d8cbc0; border-left: 1px solid #cca8a6;}
#royal1 #mainwrap .discontinued b { background: rgb(109, 221, 152); }
#royal1 #mainwrap .discontinued p { font-size: 16px; }

#spiritwalkers #mainwrap .discontinued {   background-color: #d8cbc0; border-left: 1px solid #cca8a6;}
#spiritwalkers #mainwrap .discontinued b { background: rgb(109, 221, 152); }
#spiritwalkers #mainwrap .discontinued p { font-size: 16px; }

#heartwild1 #mainwrap .discontinued {   background-color: #d8cbc0; border-left: 1px solid #cca8a6;}
#heartwild1 #mainwrap .discontinued b { background: rgb(109, 221, 152); }
#heartwild1 #mainwrap .discontinued p { font-size: 16px; }

#heartwild2 #mainwrap .discontinued {   background-color: #d8cbc0; border-left: 1px solid #cca8a6;}
#heartwild2 #mainwrap .discontinued b { background: rgb(109, 221, 152); }
#heartwild2 #mainwrap .discontinued p { font-size: 16px; }